import React from "react"

import { Container, Row, Col } from "reactstrap"
import Link from "../components/link"
import Button from "../components/btn"
import Layout from "../components/layout"
import Box from "../components/box"
import Hr from "../components/hr"
import styled from "styled-components"
import { StaticImage } from 'gatsby-plugin-image'
import Img from "gatsby-image"

let StyledBackground = styled.div`
  background: linear-gradient(to bottom, #f9fbfd 0, #fff 100%);
`

let Product = ({ title, picFile }) => (
  <Col xs={12} md={3} className="mb-3">
    <div style="line-height: 30px;"><span style="font-size: 16px;">{title}</span><br style="" /></div>
    <StaticImage src="../data/product/p1.webp" />
  </Col>
)

let Index = () => (
  <Layout>
    <Container className="pt-4">
      <div className="text-center">
        <h4>产品展示</h4>
        <p className="text-muted">
          <b><span style="color: rgb(30, 80, 174);">——</span></b>
        </p>
      </div>
    </Container>
    <Container className="py-5">
      <Row>
        <Col xs={12} md={3} className="mb-3">
          <Link to="product/pfirst">
            <div style="line-height: 30px;"><span style="font-size: 16px;">列车接近道口报警设备</span><br style="" /></div>
            <StaticImage src="../data/product/p1.webp" />
          </Link>
        </Col>

        <Col xs={12} md={3} className="mb-3">
          <Link to="product/p2">
            <div style="line-height: 30px;"><span style="font-size: 16px;">铁路道口故障报警设备</span><br style="" /></div>
            <StaticImage src="../data/product/p2.webp" />
          </Link>
        </Col>

        <Col xs={12} md={3} className="mb-3">
          <Link to="product/p3">
            <div style="line-height: 30px;"><span style="font-size: 16px;">铁路道口视频监控设备</span><br style="" /></div>
            <StaticImage src="../data/product/p3.webp" />
          </Link>
        </Col>

        <Col xs={12} md={3} className="mb-3">
          <Link to="product/p4">
            <div style="line-height: 30px;"><span style="font-size: 16px;">道口品字形信号灯</span><br style="" /></div>
            <StaticImage src="../data/product/p4.webp" />
          </Link>
        </Col>

        <Col xs={12} md={3} className="mb-3">
          <Link to="product/p5">
            <div style="line-height: 30px;"><span style="font-size: 16px;">报警室外装置</span><br style="" /></div>
            <StaticImage src="../data/product/p5.jpg" />
          </Link>
        </Col>

        <Col xs={12} md={3} className="mb-3">
          <Link to="product/p6">
            <div style="line-height: 30px;"><span style="font-size: 16px;">道口警示灯</span><br style="" /></div>
            <StaticImage src="../data/product/p6.jpg" />
          </Link>
        </Col>

       
        <Col xs={12} md={3} className="mb-3">
          <Link to="product/p8">
            <div style="line-height: 30px;"><span style="font-size: 16px;">道口综合防护系统</span><br style="" /></div>
            <StaticImage src="../data/product/p8.jpg" />
          </Link>
        </Col>

        <Col xs={12} md={3} className="mb-3">
        <Link to="product/p9">
          <div style="line-height: 30px;"><span style="font-size: 16px;">道口综合控制台</span><br style="" /></div>
          <StaticImage src="../data/product/p9.jpg" />
          </Link>
        </Col>

        <Col xs={12} md={3} className="mb-3">
        <Link to="product/p10">
          <div style="line-height: 30px;"><span style="font-size: 16px;">公路信号灯</span><br style="" /></div>
          <StaticImage src="../data/product/p10.jpg" />
          </Link>
        </Col>

        <Col xs={12} md={3} className="mb-3">
          <Link to="product/p11">
            <div style="line-height: 30px;"><span style="font-size: 16px;">铁路道口栏杆</span><br style="" /></div>
            <StaticImage src="../data/product/p11.jpg" />
          </Link>
        </Col>

        

        <Col xs={12} md={3} className="mb-3">
          <Link to="product/p14">
            <div style="line-height: 30px;"><span style="font-size: 16px;">铁路道口栏木机</span><br style="" /></div>
            <StaticImage src="../data/product/p14.jpg" />
          </Link>
        </Col>

       
        <Col xs={12} md={3} className="mb-3">
          <Link to="product/p16">
            <div style="line-height: 30px;"><span style="font-size: 16px;">无人道口报警器</span><br style="" /></div>
            <StaticImage src="../data/product/p16.jpg" />
          </Link>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default Index
